import request from './request'

// 续保配置列表
export function configList(params) {
  return request({
    method: 'POST',
    url: '/Renewal/configList',
    data: params,
  })
}

// 续保配置列表
export function configDel(params) {
  return request({
    method: 'POST',
    url: '/Renewal/configDel',
    data: params,
  })
}

// 续保配置导入
export function configImport(params){
  return request({
    method:'POST',
    url:'/Renewal/configImport',
    data:params
  })
}